.tic-tac-toe {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tic-tac-toe-row {
  display: flex;
}

.tic-tac-toe-square {
  background: #fff;
  border: 1px solid #999;
  width: 150px;
  height: 150px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  font-size: 75px;
  font-weight: bold;
}

.tic-tac-toe-reset {
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .tic-tac-toe-square {
    width: 25vw;
    height: 25vw;
    font-size: 10vw;
  }
}
